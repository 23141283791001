module.exports ={
	hows: [
		{
			title: "SAVE MONEY AND TIME",
			subDetails: "Book your trips hassle-free within the comfort of your home",
			icon: "vector/feature-icon-pay.png"
		},
		{
			title: "REALTIME UPDATES",
			subDetails: "Get realtime updates & notifications regarding your trips",
			icon: "vector/feature-icon-phone.png"
		},
		{
			title: "CONTACT TRACING READY",
			subDetails: "Reach your destination with peace of mind through government registered vans",
			icon: "vector/feature-icon-seats.png"
		}

	]
}
