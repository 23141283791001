import React from "react"
import howItWorks from "../modules/how-it-works"
import { useStaticQuery, graphql } from "gatsby";
import "../css/how-it-works.css"

const HowItWorks = () =>{
	const HOW_IT_WORKS = howItWorks.hows;
	const staticImages = useStaticQuery(graphql`
		{
			allFile {
				nodes {
				relativePath
				publicURL
				}
			}
		}
	`);

	const getStaticImage = (imagePath) => {
		const allStaticImages = staticImages.allFile.nodes;
		let output = allStaticImages.find(img => img.relativePath === imagePath);
		let publicURL = output.publicURL;

		return publicURL;
	};
	return(
		<>
			<div className="how-it-works-container">
				{
					HOW_IT_WORKS.map((how, index) => (
						<div className="how-it-works-feature" key={index}>
							<img
								src={getStaticImage(how.icon)}
								className="how-it-works-feature-icon"
								alt={how.title}
							/>
							<div className="how-it-works-feature-content">
								<h2 className="how-it-works-feature-content-title">{how.title}</h2>
								<p className="how-it-works-feature-content-subDetails">{how.subDetails}</p>
							</div>
						</div>
					))
				}
			</div>
		</>
	)
}

export default HowItWorks
