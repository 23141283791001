import React from "react"
import LOGO from "../assets/logo/vanwagon-square-logo.png"
import { useStaticQuery, graphql } from "gatsby";
import paymentOptionsLogo from "../modules/payment-options-list"
import "../css/about-us-section.css"

const AboutUs = () => {
	const PAYMENT_OPTIONS = paymentOptionsLogo.options;
	const staticImages = useStaticQuery(graphql`
		{
			allFile {
				nodes {
				relativePath
				publicURL
				}
			}
		}
	`);

	const getStaticImage = (imagePath) => {
		const allStaticImages = staticImages.allFile.nodes;
		let output = allStaticImages.find(img => img.relativePath === imagePath);
		let publicURL = output.publicURL;

		return publicURL;
	};
	return(
		<>
			<div className="about-us-section-container" id="about-us-section-container">
				<h1 className="about-us-section-title">About Us</h1>
				<div className="about-us-section-row">
					<div className="about-us-card">
						<div className="about-us-card-inner">
							<div className="about-us-card-front-left">
								<img className="about-us-vanwagon-logo" src={LOGO} alt="vanwagon logo"/>

								<div className="about-us-details">
									<h2 className="about-us-title">VANWAGON PH</h2>
									<p className="about-us-summary">
										With the new online platform, passengers can experience paperless transactions, various payment options and sure seats. They will also be provided with free face masks.

										<br/>
										<br/>
										The booking services reminded that passengers coming from Clark going to Baguio City can board via the Dau Terminal. It said that the terminal is just a few minutes away from Clark International Airport....

										<a href="https://news.biyaheroes.com/about-vanwagon-ph/" target="_blank" rel="noreferrer">
											READ MORE
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>


				</div>
				<div className="about-us-section-row">
					<div className="about-us-card">
						<div className="about-us-card-inner">
								<div className="about-us-card-front-bottom">

									<div className="about-us-payment-options-details">
										<h2 className="about-us-payment-option-title">PAYMENT OPTIONS</h2>
										{
											PAYMENT_OPTIONS.map((option, index) => (
												<img
													src={getStaticImage(option.image)}
													className="about-us-payment-options-logo"
													key={index}
													alt={option.title}
												/>
											))
										}

									</div>
								</div>
							</div>
						</div>
				</div>
			</div>
		</>
	)
}

export default AboutUs
