import React, {useState, useEffect } from "react"
import slideShow from "../modules/slide-show"
import { useStaticQuery, graphql } from "gatsby";
import "../css/slide-show.css"


const SlideShow = () => {
	const SLIDES = slideShow.slides;
	const staticImages = useStaticQuery(graphql`
		{
			allFile {
				nodes {
				relativePath
				publicURL
				}
			}
		}
	`);

	const getStaticImage = (imagePath) => {
		const allStaticImages = staticImages.allFile.nodes;
		let output = allStaticImages.find(img => img.relativePath === imagePath);
		let publicURL = output.publicURL;

		return publicURL;
	};

	const [slideIndex, setSlideIndex ] = useState(1);

	const changeSlides = (value) =>{
		showSlide(setSlideIndex(slideIndex + value));
	}
	const currentSlide = (value) =>{
		showSlide(setSlideIndex(value));
	}
	const showSlide = (value) =>{
		var i;
		var slides = document.getElementsByClassName("slide-container");
		var dots = document.getElementsByClassName("slider-dot");
		if (value > slides.length) {
			setSlideIndex(1)
		}
		if (value < 1) {
			setSlideIndex(slides.length)
		}
		for (i = 0; i < slides.length; i++) {
			slides[i].style.display = "none";
		}
		for (i = 0; i < dots.length; i++) {
			dots[i].className = dots[i].className.replace(" active-slide", "");
		}
		slides[slideIndex-1].style.display = "block";
		dots[slideIndex-1].className += " active-slide";
	}

	useEffect(() => {
		showSlide(slideIndex);
	});

	const scrollDown = (id) => {

		setTimeout(function(){
			let element = document.getElementById(id);
			element.scrollIntoView({ behavior: "smooth" });
		}, 0)

	};

	return(
		<>
			<div className="slideshow-container">
				{
					SLIDES.map((slide, index) => (

							<div
								className="slide-container slides-fade"
								style={{
									backgroundImage: `url(${ getStaticImage(slide.background) })`
								}}
								key={index}>
									<div className="slide-content-container">
										<h1 className="slide-title"> {slide.title } </h1>
										<h2 className="slide-sub-title">{ slide.subTitle }</h2>

										<hr style={{width: "50%", borderTop: "solid 5px white"}}/>
										<p className="slide-sub-details" >{ slide.subDetails }</p>

										{
											slide.button_label === "CHECK OUR TRANSPORT PARTNERS!" ?
											<button className="slide-btn" onClick={() => scrollDown("middle-section-container")}>{ slide.button_label}</button> :
											<a href={slide.link} target="_blank" rel="noreferrer">
												<button className="slide-btn">{ slide.button_label}</button>
											</a>
										}

									</div>
							</div>

					))
				}


				{slideIndex === 1 ? "" :
					<button className="slide-prev" onClick={() => changeSlides(-1)}>&#10094;</button>
				}
				{SLIDES.length === slideIndex ? "" :
					<button className="slide-next" onClick={() => changeSlides(1)}>&#10095;</button>
				}

			</div>

			<div className="slider-dot-container">
				{
					SLIDES.map((slide, index) =>(
						<button className="slider-dot" onClick={() => currentSlide(index + 1)} key={index}> </button>
					))
				}
			</div>

		</>
	)
}

export default SlideShow
