module.exports ={
	partners: [
		{
			title: "Baguio | Pampanga | Pangasinan | Cabanatuan | Manila",
			link: "https://booktouristvan.com/",
			button_label: "BOOK NOW",
			background_image: "img/card-left-background.png",
			details: "CLICK ME!",
			logo: "logo/bcttc-white-logo.png",
			siteName: "BOOKTOURISTVAN.COM",
			transport: "BAGUIO CITY TOURIST TRANSPORT COOPERATIVE"
		},
		{
			title: "Batangas | Laguna | Buendia, Pasay",
			link: "https://touristvans.com/",
			button_label: "BOOK NOW",
			background_image: "img/card-right-background.png",
			details: "CLICK ME!",
			logo: "logo/ett-white-logo.png",
			siteName: "TOURISTVANS.COM",
			transport: "STA ROSA INTEGRATED TERMINAL"
		}
	]
}
