module.exports = {
	siteDetails: {
		siteTitle: "Vanwagon",
		decription: "Your destination is only a van ride away!",
		sharingImage: "",
		author: "Vanwagon"
	},
	contactInfo : {
		officeAddress: "Unit 6Y, #75 OBS Building III, Lopez Jaena St., Brgy Doña Aurora, Quezon City, Metro Manila, NCR, Philippines 1113",
		customerHotlines: "( +63 ) 917 535 1501 | ( +63 ) 2 7507 8575",
		officeNumber: "( +63 ) 2 7507 8656"
	},
	socialMediaAccounts : {
		facebookPage: "https://facebook.com/vanwagonph",
		twitterPage: "https://twitter.com/vanwagonph",
		instagramPage: "https://instagram.com/vanwagonph",
	},
	generalLinks: {
		aboutUs: "https://blog.biyaheroes.com/about-biyaheroes",
		help: "https://blog.biyaheroes.com/frequently-asked-questions",
		blog: "https://blog.biyaheroes.com",
		news: "https://news.biyaheroes.com",
		schedules: "http://search.biyaheroes.com/",
		privacyPolicy: "https://blog.biyaheroes.com/our-privacy-policy",
		workWithUs: "",
		bookNow: ""
	},
	sponsorsLink: {
		biyaheroes: "https://biyaheroes.com/"
	}
}
