import React from "react";
import blogsEntries from "../modules/blog";
import { useStaticQuery, graphql } from "gatsby";
import "../css/blog-section.css";

const MiddleSection = () => {
	const BLOGS_ENTRIES = blogsEntries.blogs;
	const staticImages = useStaticQuery(graphql`
		{
			allFile {
				nodes {
				relativePath
				publicURL
				}
			}
		}
	`);

	const getStaticImage = (imagePath) => {
		const allStaticImages = staticImages.allFile.nodes;
		let output = allStaticImages.find(img => img.relativePath === imagePath);
		let publicURL = output.publicURL;

		return publicURL;
	};

	return (
		<>
			<div className="blog-section-container" id="blog-section-container">
				<h1 className="blog-section-title">Blogs</h1>
				<div className="blog-section-row">
					{
						BLOGS_ENTRIES.map((blog, index) => (
							<a href={blog.link} target="_blank" rel="noreferrer" key={index}>
								<div className="" key={ index }>
									<div className="blog-card">
										<div className="blog-card-inner">
											<div
												className="blog-card-front-left"
												style={{
													backgroundImage: `url(${ getStaticImage(blog.image) })`
												}}
											>
												<div className="blog-card-cover">
													<h3 className="blog-card-title">{ blog.title }</h3>

													<p className="blog-card-details">{ blog.summary }</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</a>
						))
					}
				</div>
			</div>
		</>
	);
}

export default MiddleSection;
