module.exports = {
	blogs: [
		{
			title: "Required travel permits needed!",
			summary: "As the government continues to ease travel restrictions, the Philippine National Police (PNP) issues Travel Pass-through Permits (TPP)...READ MORE",
			image: "img/blog-entry-permit.JPG",
			link: "https://news.biyaheroes.com/travel-updates-list-of-ph-province-that-require-and-do-not-require-travel-permit-when-traveling/"
		},
		{
			title: "Baguio City to Manila",
			summary: "Baguio reopens its doors for tourist guests and returning residents....READ MORE",
			image: "img/card-left-background.png",
			link: "https://news.biyaheroes.com/book-you-van-trips-from-baguio-city-to-manila/"
		},
		{
			title: "It's VANtastic new routes!",
			summary: "Travellers going to Baguio must register at the Baguio's VISITA (Visitor Information and Travel Assistance) online portal prior to arrival....READ MORE",
			image: "img/bcttc-slide-background.JPG",
			link: "https://news.biyaheroes.com/vantastic-book-your-van-trips-now/"
		},

	]
}
