import React from "react";

import MainSection from "../sections/main-section.js";
import MiddleSection from "../sections/middle-section.js";
import BlogSection from "../sections/blog-section"
import AboutUs from "../sections/about-us-section"
import FooterSection from "../sections/footer-section.js";
import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => (
	<Layout>
		<SEO title="Home" />
		<MainSection/>
		<MiddleSection/>
		<BlogSection/>
		<AboutUs/>
		<FooterSection/>
	</Layout>
);

export default IndexPage;
