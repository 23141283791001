import React from "react";
import siteConfig from "../modules/siteConfig"
import facebookIcon from "../assets/icons/facebook-icon.png"
import twitterIcon from "../assets/icons/twitter-icon.png"
import instagramIcon from "../assets/icons/instagram-icon.png"
import logo from "../assets/logo/biyaheroes-logo.png"
import "../css/home.css";

const FooterSection = () => (
	<>
		<div className="footer-section-wrapper">
			<div className="footer-column-left">
				<h5>Contact Us</h5>
				<p>{ siteConfig.contactInfo.officeAddress }</p>
				<h6>Customer Hotline</h6>
				<p>{ siteConfig.contactInfo.customerHotlines }</p>
				<h6>Office Number</h6>
				<p>{ siteConfig.contactInfo.officeNumber }</p>
			</div>
			<div className="footer-column-center">
				<h5>General Links</h5>
				<ul>
					<li className="footer-general-links">Book Now</li>
					<li>
						<a
							href={ siteConfig.generalLinks.aboutUs }
							target="_blank"
							rel="noreferrer"
							className="footer-general-links"
						>About Us</a>
					</li>
					<li>
						<a
							href={ siteConfig.generalLinks.news }
							target="_blank"
							rel="noreferrer"
							className="footer-general-links"
						>News</a>
					</li>
					<li>
						<a
							href={ siteConfig.generalLinks.blog }
							target="_blank"
							rel="noreferrer"
							className="footer-general-links"
						>Blog</a>
					</li>
					<li>
						<a
							href={ siteConfig.generalLinks.help }
							target="_blank"
							rel="noreferrer"
							className="footer-general-links"
						>Help</a>
					</li>
					<li>
						<a
							href={ siteConfig.generalLinks.schedules }
							target="_blank"
							rel="noreferrer"
							className="footer-general-links"
						>Schedules</a>
					</li>
					<li>
						<a
							href={ siteConfig.generalLinks.privacyPolicy }
							target="_blank"
							rel="noreferrer"
							className="footer-general-links"
						>Privacy Policy</a>
					</li>

					<li className="footer-general-links">Work with Us!</li>
				</ul>
			</div>
			<div className="footer-column-right">
				<h5>Social Media</h5>
				<a
					href={ siteConfig.socialMediaAccounts.facebookPage}
					target="_blank"
					rel="noreferrer"
				>
					<img src={facebookIcon} className="footer-social-media-icons" alt="facebook logo"/>
				</a>
				<a
					href={ siteConfig.socialMediaAccounts.facebookPage}
					target="_blank"
					rel="noreferrer"
				>
					<img src={twitterIcon} className="footer-social-media-icons" alt="twitter logo"/>
				</a>
				<a
					href={ siteConfig.socialMediaAccounts.facebookPage}
					target="_blank"
					rel="noreferrer"
				>
					<img src={instagramIcon} className="footer-social-media-icons" alt="instagram logo"/>
				</a>

				<div className="footer-sponsor-detail">
					<p style={{ margin: "0px", fontSize: "12px"}}>Powered by</p>
					<a
						href={ siteConfig.sponsorsLink.biyaheroes}
						target="_blank"
						rel="noreferrer"
					>
						<img src={ logo } style={{ width: "180px", margin: "0px"}} className="footer-social-media-icons" alt="biyaheroes logo"/>
					</a>
				</div>
			</div>
		</div>
	</>
);

export default FooterSection;
