import React from "react";
//import Widget from "../components/widget.js";
//import MiddleSection from "./middle-section.js";
//import slideShow from "../modules/slide-show"
//import BackgroundImage from "../assets/img/main-section-background.png"
import SlideShow from "../components/slide-show";
import HowItWorks from "../components/how-it-works"

const MainSection = () => {

	return (
		<>
			<div id="main-section-container">
				<SlideShow/>
				<HowItWorks/>
			</div>
		</>
	);
}

export default MainSection;
