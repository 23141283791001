import React from "react";
import travelPartners from "../modules/travel-partners";
import { useStaticQuery, graphql } from "gatsby";
import "../css/home.css";

const MiddleSection = () => {
	const TRAVEL_PARTNERS = travelPartners.partners;
	const staticImages = useStaticQuery(graphql`
		{
			allFile {
				nodes {
				relativePath
				publicURL
				}
			}
		}
	`);

	const getStaticImage = (imagePath) => {
		const allStaticImages = staticImages.allFile.nodes;
		let output = allStaticImages.find(img => img.relativePath === imagePath);
		let publicURL = output.publicURL;

		return publicURL;
	};

	return (
		<>
			<div className="middle-section-container" id="middle-section-container">
				<h1 className="middle-section-title">Our Travel Partners</h1>
				<div className="middle-section-row">
					{
						TRAVEL_PARTNERS.map((site, index) => (
							<div className="" key={ index }>
								<div className="flip-card">
									<div className="flip-card-inner">
										<div
											className="flip-card-front-left"
											style={{
												backgroundImage: `url(${ getStaticImage(site.background_image) })`
											}}
										>
											<div className="flip-card-cover">
												<h3 className="flip-card-title">{ site.title }</h3>


												<h3 className="flip-card-site-name">{ site.siteName }</h3>
												<p className="flip-card-transport">{ site.transport }</p>

												<img src={getStaticImage(site.logo)} className="flip-card-transport-logo" alt={site.transport}/>

												<p className="flip-card-details">{ site.details }</p>
											</div>
										</div>
										<div className="flip-card-back">
											<a
												href={ site.link }
												target="_blank"
												rel="noreferrer"
												className="flip-card-link"
											>
												<h3>{ site.button_label }</h3>
											</a>
										</div>
									</div>
								</div>
							</div>
						))
					}
				</div>
			</div>
		</>
	);
}

export default MiddleSection;
