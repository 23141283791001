module.exports= {
	options : [
		{
			title: "GCASH",
			image: "payment-options/gcash-logo.png"
		},
		{
			title: "PAYPAL",
			image: "payment-options/paypal-logo.png"
		},
		{
			title: "7/11",
			image: "payment-options/711-logo.png"
		},
		{
			title: "Bayad Center",
			image: "payment-options/bayad-center-logo.png"
		},
		{
			title: "Mlhuillier",
			image: "payment-options/mlhuillier-logo.png"
		},
		{
			title: "Cebuana Lhuillier",
			image: "payment-options/cebuana-logo.png"
		},
		{
			title: "LBC",
			image: "payment-options/lbc-logo.png"
		},
		{
			title: "Robinson's Department Store",
			image: "payment-options/robinsons-logo.png"
		},
		{
			title: "SM Department Store",
			image: "payment-options/sm-logo.png"
		},
		{
			title: "BDO",
			image: "payment-options/bdo-logo.png"
		},
		{
			title: "BPI",
			image: "payment-options/bpi-logo.png"
		},
		{
			title: "Chinabank",
			image: "payment-options/china-bank-logo.png"
		},
		{
			title: "Eastwest",
			image: "payment-options/eastwest-logo.png"
		},
		{
			title: "Metrobank",
			image: "payment-options/metrobank-logo.png"
		},
		{
			title: "PNB",
			image: "payment-options/pnb-logo.png"
		},
		{
			title: "RCBC",
			image: "payment-options/rcbc-logo.png"
		},
		{
			title: "Security Bank",
			image: "payment-options/security-bank-logo.png"
		},
		{
			title: "UCPB",
			image: "payment-options/ucpb-logo.png"
		},
		{
			title: "Unionbank",
			image: "payment-options/union-bank-logo.png"
		},
	]
}
