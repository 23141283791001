module.exports = {
	content : {
		title: "Book your van seats online.",
		subTitle: "Baguio • Pampanga • Pangasinan • Cabanatuan • Batangas • Laguna • Manila",
		subDetails: "Reserve your van seats online and pay your booking reservation through our online payment channels such as GCash, Paypal, Online banking, and more.",
		button_label: "CHECK OUR TRAVEL PARTNERS HERE!",
		link: ""
	},
	slides : [
		{
			title: "Book your van seats online.",
			subTitle: "Baguio • Pampanga • Pangasinan • Cabanatuan • Batangas • Laguna • Manila",
			subDetails: "Reserve your van seats online and pay your booking reservation through our online payment channels such as GCash, Paypal, Online banking, and more.",
			button_label: "CHECK OUR TRANSPORT PARTNERS!",
			link: "https://booktouristvan.com/",
			background: "img/transport-background.png"
		},
		{
			title: "Book your van seats online.",
			subTitle: "Baguio • Pampanga • Pangasinan • Cabanatuan",
			subDetails: "Reserve your van seats online and pay your booking reservation through our online payment channels such as GCash, Paypal, Online banking, and more.",
			button_label: "BOOK NOW",
			link: "https://booktouristvan.com/",
			background: "img/bcttc-slide-background.JPG"
		},
		{
			title: "Book your van seats online.",
			subTitle: "Batangas • Laguna • Manila",
			subDetails: "Reserve your van seats online and pay your booking reservation through our online payment channels such as GCash, Paypal, Online banking, and more.",
			button_label: "TRAVEL NOW",
			link: "https://touristvans.com/",
			background: "img/ett-slide-background.JPG"
		}
	]
}
